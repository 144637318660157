import React from "react";
// import Container from "react-bootstrap/Container";
// import Navbar from "react-bootstrap/Navbar";
import { Nav, Navbar, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../styles/NavBar.css";

const NavBar = (props) => {
  return (
    <>
      <header>
        <Navbar collapseOnSelect expand="lg">
          <Container>
            <Navbar.Brand href="#home" className="navbar-brand hover">
              <div className="">
                <Link to="/">Hirdesh Gupta</Link>
                <p>Social activist & IDUK leader</p>
              </div>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse
              id="responsive-navbar-nav"
              className="navbar-menu me-auto"
            >
              <Nav className="me-auto"></Nav>
              <Nav>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/gallery">Gallery</Link>
                </li>
                <li>
                  <Link to="/bio">BioGraphy</Link>
                </li>
                <li>
                  <Link to="/social">Social</Link>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        {/* <div className="container">
          <nav className="navbar">
            <div className="navbar-brand hover">
              <div className="">
                <Link to="/">Hirdesh Gupta</Link>
                <p>Social activist & IDUK leader</p>
              </div>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-menu navbar-nav ms-auto">
                <li>
                  <Link to="/">Home</Link>
                </li>
                {/* [<li>
                  <Link to="/events">Events</Link>
                </li>] */}
        {/* <li>
                  <Link to="/gallery">Gallery</Link>
                </li>
                <li>
                  <Link to="/bio">BioGraphy</Link>
                </li>
                <li>
                  <Link to="/social">Social</Link>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
            </div>
          </nav>
        </div> */}
      </header>
    </>
  );
};

export default NavBar;
