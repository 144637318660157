import React from "react";
import "../../styles/Social.css";
import Layout from "../../components/Layout/Layout";
import s1 from "../../assets/images/s1.png";
import s2 from "../../assets/images/s2.png";
import s3 from "../../assets/images/s3.png";
import s4 from "../../assets/images/s4.png";
import s5 from "../../assets/images/s5.png";
import s6 from "../../assets/images/s6.png";
import s7 from "../../assets/images/s7.png";
import s8 from "../../assets/images/s8.png";
import instagram1 from "../../assets/images/instagram1.png";
import facebook from "../../assets/images/facebook.png";
import f1 from "../../assets/images/f1.png";
import f2 from "../../assets/images/f2.png";
import f3 from "../../assets/images/f3.png";
import f4 from "../../assets/images/f4.png";
import f5 from "../../assets/images/f5.png";
import f6 from "../../assets/images/f6.png";
import f7 from "../../assets/images/f7.png";
import f8 from "../../assets/images/f8.png";
import twitter1 from "../../assets/images/twitter1.png";
import t1 from "../../assets/images/t1.png";
import t2 from "../../assets/images/t2.png";
import t3 from "../../assets/images/t3.png";
import t4 from "../../assets/images/t4.png";
import t5 from "../../assets/images/t5.png";
import t6 from "../../assets/images/t6.png";
import t7 from "../../assets/images/t7.png";
import t8 from "../../assets/images/t8.png";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
// import InstagramFeed from "../../components/InstagramFeed";
import InstagramFeed from "react-ig-feed";
import { FacebookProvider, EmbeddedPost } from "react-facebook";
// import LatestTweets from "../../components/LatestTweets";
import BannerSection from "../../assets/images/new_images/banner1.jpeg";

const Default = () => {
  return (
    <Layout>
      <section
        className="page_banner"
        style={{
          backgroundImage: `url(${BannerSection})`,
        }}
      >
        <Container>
          <Row>
            <Col md={12}>
              <h1>Social</h1>
            </Col>
          </Row>
        </Container>
      </section>
      {/* <FacebookProvider appId="233834769188939">
        <EmbeddedPost href="http://www.facebook.com" width="500" />
      </FacebookProvider> */}
      {/* <InstagramFeed token="964a110d000dea917b76928e976c2b97" counter="6" /> */}
      {/* <LatestTweets /> */}
      <div className="social-page">
        <section className="insta_social_feed social_feed">
          <Container>
            <Row>
              <Col md={12}>
                <div className="social-icon">
                  <Link
                    to="https://www.instagram.com/hirdeshg/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={instagram1}
                      height={60}
                      width={65}
                      alt="Instagram Logo"
                    />
                  </Link>
                  <p>My Instagram</p>
                </div>
                <div className="thumbnail-scroll">
                  <Link
                    to="https://www.instagram.com/p/Cnrz-c-MuFH/?utm_source=ig_web_copy_link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={s1} alt="Thumbnail 1" />
                  </Link>
                  <Link
                    to="https://www.instagram.com/p/CohWwglDv-l/?utm_source=ig_web_copy_link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={s2} alt="Thumbnail 1" />
                  </Link>
                  <Link
                    to="https://www.instagram.com/reel/CpKFHgmu6VG/?utm_source=ig_web_copy_link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={s3} alt="Thumbnail 1" />
                  </Link>
                  <Link
                    to="https://www.instagram.com/p/Cnnge14KKXv/?utm_source=ig_web_copy_link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={s4} alt="Thumbnail 1" />
                  </Link>
                  <Link
                    to="https://www.instagram.com/p/CniLDg1NUCg/?utm_source=ig_web_copy_link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={s5} alt="Thumbnail 1" />
                  </Link>
                  <Link
                    to="https://www.instagram.com/p/CngD9omvztH/?utm_source=ig_web_copy_link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={s6} alt="Thumbnail 1" />
                  </Link>
                  <Link
                    to="https://www.instagram.com/p/CnVeo7pJ6zI/?utm_source=ig_web_copy_link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={s7} alt="Thumbnail 1" />
                  </Link>
                  <Link
                    to="https://www.instagram.com/p/CnIvcK5MxWK/?utm_source=ig_web_copy_link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={s8} alt="Thumbnail 1" />
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* FaceBook */}
        <section className="facebook_social_feed social_feed">
          <Container>
            <Row>
              <Col md={12}>
                <div className="social-icon">
                  <Link
                    to="https://www.facebook.com/guptahirdesh"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={facebook}
                      height={50}
                      width={50}
                      alt="Instagram Logo"
                    />
                  </Link>
                  <p>My Facebook</p>
                </div>
                <div className="thumbnail-scroll">
                  <Link
                    to="https://www.facebook.com/photo/?fbid=10160389471788151&set=pcb.10160389472648151"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={f1} alt="Thumbnail 1" />
                  </Link>
                  <Link
                    to="https://www.facebook.com/photo/?fbid=10160707880338151&set=pcb.10160707912093151"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={f2} alt="Thumbnail 1" />
                  </Link>
                  <Link
                    to="https://www.facebook.com/photo/?fbid=1556594414498947&set=pcb.1556594507832271"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={f3} alt="Thumbnail 1" />
                  </Link>
                  <Link
                    to="https://www.facebook.com/photo/?fbid=10160684672398151&set=pcb.10160684683728151"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={f4} alt="Thumbnail 1" />
                  </Link>
                  <Link
                    to="https://www.facebook.com/photo?fbid=10160669233753151&set=a.10151211190148151"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={f5} alt="Thumbnail 1" />
                  </Link>
                  <Link
                    to="https://www.facebook.com/photo/?fbid=10160647421278151&set=pcb.10160647433678151"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={f6} alt="Thumbnail 1" />
                  </Link>
                  <Link
                    to="https://www.facebook.com/photo/?fbid=10160644939203151&set=pcb.10160644934423151"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={f7} alt="Thumbnail 1" />
                  </Link>
                  <Link
                    to="https://www.facebook.com/photo/?fbid=10160631272108151&set=pcb.10160631307608151"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={f8} alt="Thumbnail 1" />
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Twitter */}
        <section className="twitter_social_feed social_feed">
          <Container>
            <Row>
              <Col md={12}>
                <div className="social-icon">
                  <Link
                    to="https://twitter.com/guptahirdesh"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={twitter1}
                      height={60}
                      width={65}
                      alt="Instagram Logo"
                    />
                  </Link>
                  <p>My Twitter</p>
                </div>
                <div className="thumbnail-scroll">
                  <Link
                    to="https://twitter.com/guptahirdesh/status/1620359625865560064/photo/1"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={t1} alt="Thumbnail 1" />
                  </Link>
                  <Link
                    to="https://twitter.com/guptahirdesh/status/1618572009444868097/photo/1"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={t2} alt="Thumbnail 1" />
                  </Link>
                  <Link
                    to="https://twitter.com/guptahirdesh/status/1618571500021506049/photo/1"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={t3} alt="Thumbnail 1" />
                  </Link>
                  <Link
                    to="https://twitter.com/guptahirdesh/status/1618570772985049088/photo/4"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={t4} alt="Thumbnail 1" />
                  </Link>
                  <Link
                    to="https://twitter.com/guptahirdesh/status/1618570152978808832/photo/1"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={t5} alt="Thumbnail 1" />
                  </Link>
                  <Link
                    to="https://twitter.com/guptahirdesh/status/1616510134951481345/photo/1"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={t6} alt="Thumbnail 1" />
                  </Link>
                  <Link
                    to="https://twitter.com/guptahirdesh/status/1613239071232622592/photo/1"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={t7} alt="Thumbnail 1" />
                  </Link>
                  <Link
                    to="https://twitter.com/guptahirdesh/status/1613160732883046402/photo/2"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={t8} alt="Thumbnail 1" />
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </Layout>
  );
};

export default Default;
