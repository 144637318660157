import React from "react";
import Layout from "../../components/Layout/Layout";
import HelpForm from "../home/components/HelpForm";
import { Col, Container, Row } from "react-bootstrap";
import BannerSection from "../../assets/images/new_images/banner1.jpeg";

const Default = () => {
  return (
    <Layout>
      <section
        className="page_banner"
        style={{
          backgroundImage: `url(${BannerSection})`,
        }}
      >
        <Container>
          <Row>
            <Col md={12}>
              <h1>Contact Us</h1>
            </Col>
          </Row>
        </Container>
      </section>

      <HelpForm />
    </Layout>
  );
};

export default Default;
