import React, { useState } from "react";
import "lightbox.js-react/dist/index.css";
import Layout from "../../components/Layout/Layout";
import "../../styles/GalleryOne.css";
import { Col, Container, Row } from "react-bootstrap";
import GalleryModal from "./components/GalleryModal";
import { AnimationOnScroll } from "react-animation-on-scroll";
import BannerSection from "../../assets/images/new_images/banner1.jpeg";
// Data for Cards
const cardData = [
  {
    id: 1,
    title: "Card 1",
    images: [
      require("../../assets/images/client1.jpg"),
      require("../../assets/images/client2.jpg"),
      require("../../assets/images/client3.jpg"),
    ],
    description:
      " Trees play a great and grand role among living being. A tree is a wonderful symbol of the mystery and vitality of life. It is a beautiful and useful unit of Nature. A tree brings man near his creator. Gardens and forests are made of trees and by trees. Different types of trees grow in surroundings by adopting the climate. They keep the atmosphere clean and make the climate balance. They bring rains and protect soil from depleting. They also provide us fruit to eat and wood to make furniture. Oxygen is made available through trees. Thus, trees have great importance in our lives.Read more on Sarthaks.com - https://www.sarthaks.com/249601/write-a-paragraph-in-about-80-words-on-any-one-of-the-following-a-the-importance-of-trees",
  },
  {
    id: 2,
    title: "Card 2",
    images: [
      require("../../assets/images/Banner1.jpg"),
      require("../../assets/images/Banner2.jpg"),
      require("../../assets/images/blog1.jpg"),
    ],
    description: "Suspendisse potenti. Fusce eget semper justo.",
  },
  {
    id: 3,
    title: "Card 3",
    images: [
      require("../../assets/images/blog1.jpg"),
      require("../../assets/images/blog2.jpg"),
      require("../../assets/images/blog3.jpg"),
    ],
    description: "Suspendisse potenti. Fusce eget semper justo.",
  },
  {
    id: 4,
    title: "Card 4",
    images: [
      require("../../assets/images/Gallery.jpg"),
      require("../../assets/images/Gallery1.jpg"),
      require("../../assets/images/Gallery2.jpg"),
    ],
    description: "Suspendisse potenti. Fusce eget semper justo.",
  },
  {
    id: 5,
    title: "Card 5",
    images: [
      require("../../assets/images/Gallery2.jpg"),
      require("../../assets/images/Gallery1.jpg"),
      require("../../assets/images/Gallery3.jpg"),
    ],
    description: "Suspendisse potenti. Fusce eget semper justo.",
  },
  {
    id: 5,
    title: "Card 6",
    images: [
      require("../../assets/images/client3.jpg"),
      require("../../assets/images/blog1.jpg"),
      require("../../assets/images/Gallery1.jpg"),
    ],
    description: "Suspendisse potenti. Fusce eget semper justo.",
  },
];

const Default = () => {
  const [showDialog, setShowDialog] = useState(false);
  const [dialogData, setDialogData] = useState([]);

  // Function to handle Card Click
  const handleCardClick = (id) => {
    let filterredData = cardData.filter((x) => x.id === id);
    console.log("filterredData", filterredData);
    setDialogData(filterredData);
    setShowDialog(true);
  };

  const handleCloseModal = () => {
    setShowDialog(false);
    setDialogData([]);
  };
  return (
    <Layout>
      <section
        className="page_banner"
        style={{
          backgroundImage: `url(${BannerSection})`,
        }}
      >
        <Container>
          <Row>
            <Col md={12}>
              <h1>Gallery</h1>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="pt-5 pb-5">
        <Container>
          <Row>
            {cardData.map((card) => (
              <Col md={6}>
                <AnimationOnScroll
                  animateIn="animate__fadeInUp animate__delay-0.5s"
                  animateOnce={true}
                >
                  <div
                    key={card.id}
                    className="gallery_grid"
                    onClick={() => handleCardClick(card.id)}
                  >
                    <img src={card.images[0]} alt={card.title} />
                    <div className="gallery_caption">
                      <h4>{card.title}</h4>
                      <div className="gallery_arrow_icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="84"
                          height="84"
                          viewBox="0 0 84 84"
                          fill="none"
                        >
                          <circle
                            cx="42"
                            cy="42"
                            r="41.75"
                            stroke="white"
                            stroke-width="0.5"
                          ></circle>
                          <path
                            d="M55.7071 42.7071C56.0976 42.3166 56.0976 41.6834 55.7071 41.2929L49.3431 34.9289C48.9526 34.5384 48.3195 34.5384 47.9289 34.9289C47.5384 35.3195 47.5384 35.9526 47.9289 36.3431L53.5858 42L47.9289 47.6569C47.5384 48.0474 47.5384 48.6805 47.9289 49.0711C48.3195 49.4616 48.9526 49.4616 49.3431 49.0711L55.7071 42.7071ZM29 43H55V41H29V43Z"
                            fill="white"
                          ></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                </AnimationOnScroll>
              </Col>
            ))}
          </Row>
        </Container>
        {showDialog && (
          <GalleryModal
            open={showDialog}
            onClose={handleCloseModal}
            dialogData={dialogData}
          />
        )}
      </section>
    </Layout>
  );
};

export default Default;
