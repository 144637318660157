import React, { useEffect, useState, useContext } from "react";
// import { Button, Col, Container, Row } from "react-bootstrap";
// import heroImg from "../assets/images/he.png";
import "../../../styles/Style.css";
import { Link } from "react-router-dom";
import "../../../styles/Banner.css";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Data } from "../../Data";
import { DataContext } from "../../../DataContext";

const Banner = () => {
  const [bannerData, setBannerData] = useState({});
  // const { data } = useContext(DataContext);
  const {
    data: {
      homePage: {
        banner: { description, title },
      },
    },
  } = useContext(DataContext);
  // console.log("🚀 ~ file: Banner.jsx:15 ~ Banner ~ webData:", image);

  useEffect(() => {
    setBannerData(Data.homePage.banner);
  }, [bannerData]);

  return (
    <>
      <section className="banner">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="banner-content">
                <AnimationOnScroll
                  animateIn="animate__fadeInDown"
                  animateOnce={true}
                >
                  <h1>{title}</h1>
                </AnimationOnScroll>
                <AnimationOnScroll
                  animateIn="animate__fadeInUp"
                  animateOnce={true}
                >
                  <p>{description}</p>
                </AnimationOnScroll>
                <AnimationOnScroll
                  animateIn="animate__fadeInUp"
                  animateOnce={true}
                >
                  <Link to="/contact" className="custom_btn">
                    {bannerData.buttonText}
                  </Link>
                </AnimationOnScroll>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
