import React, { useState, useEffect, useContext } from "react";
// import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import { BsInstagram } from "react-icons/bs";
import { ImFacebook2 } from "react-icons/im";
import { BsTwitter } from "react-icons/bs";
import { Data } from "../../pages/Data";
import "../../styles/Footer.css";
import { DataContext } from "../../DataContext";

const Footer = () => {
  const [footerData, setFooterData] = useState({});
  const {
    data: {
      footer: {
        AboutMe: { ProfileName, Bio },
        CopyRight,
      },
    },
  } = useContext(DataContext);

  console.log("ProfileName", CopyRight);

  useEffect(() => {
    setFooterData(Data.footer);
  }, [footerData]);
  // const {
  //   AboutMe: { ProfileName, Role, Organization, Bio } = {},
  //   SocialMediaLinks: { FacebookLink, TwitterLink, InstagramLink } = {},
  //   CopyRight,
  // } = footerData;
  return (
    <>
      {footerData && (
        <footer>
          <div className="container">
            <div className="row">
              <div className="col-sm-6 col-md-6">
                <div className="footer_widget about_footer">
                  <h3>About Me</h3>
                  <p>
                    I am {ProfileName} {Bio}
                  </p>
                  <ul className="d-flex social_media_footer">
                    <li>
                      <a
                        href="https://www.instagram.com/hirdeshg/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <BsInstagram />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.facebook.com/guptahirdesh"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <ImFacebook2 />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/guptahirdesh"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <BsTwitter />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-6 col-md-6">
                <div className="footer_widget quick_links">
                  <h3>Quick Links</h3>
                  <div className="row">
                    <div className="col-md-6">
                      <ul className="footer-widget-links">
                        <li>
                          <Link to="/">Home</Link>
                        </li>
                        <li>
                          <Link to="/gallery">Gallery</Link>
                        </li>
                        <li>
                          <Link to="/bio">BioGraphy</Link>
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-6">
                      <ul className="footer-widget-links">
                        <li>
                          <Link to="/contact">Contact</Link>
                        </li>
                        <li>
                          <Link to="/social">Social</Link>
                        </li>
                        {/* <li>
                          <a href="#">Blog</a>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="col-sm-12 col-md-3">
                <div className="footer_widget">
                  <h3>Contact Me</h3>
                  <ul className="footer-widget-address">
                    <li>
                      <i className="fa fa-envelope"></i> {Email}
                    </li>
                    <li>
                      <i className="fa fa-phone"></i> {Phone}
                    </li>
                    <li>
                      <i className="fa fa-map-marker"></i> {Address}
                    </li>
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
          <div className="copyright_text">
            <p className="">&copy; {"2023 My Portfolio Website"}</p>
          </div>
        </footer>
      )}
    </>
  );
};

export default Footer;
