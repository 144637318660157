import React, { useState, useEffect, useContext } from "react";
import "../../../styles/HelpText.css";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Data } from "../../Data";
import { DataContext } from "../../../DataContext";

const HelpText = () => {
  const [helpData, setHelpData] = useState({});

  const {
    data: {
      homePage: {
        helpText: { gridData, heading },
      },
    },
  } = useContext(DataContext);

  useEffect(() => {
    setHelpData(Data.homePage.helpText);
  }, [helpData]);

  return (
    <section className="logo-section">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="section_title text-center">
              <AnimationOnScroll animateIn="animate__zoomIn" animateOnce={true}>
                <h2 className="">{heading}</h2>
              </AnimationOnScroll>
            </div>
            <div className="row">
              {gridData && (
                <>
                  {gridData.map((v, i) => {
                    return (
                      <div className="col-md-4" key={`help_list_data_${i}`}>
                        <AnimationOnScroll
                          animateIn="animate__fadeInUp animate__delay-0.5s"
                          animateOnce={true}
                        >
                          <div className="logo-item">
                            <div className="logo_icon">
                              <img
                                src={`${process.env.REACT_APP_BASE_URL}/${v.logo}`}
                                alt="Social Logo"
                              />
                            </div>
                            <h3 className="logo-title">{v.logoTitle}</h3>
                            <p className="logo-description">
                              {v.logoDescription}
                            </p>
                          </div>
                        </AnimationOnScroll>
                      </div>
                    );
                  })}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HelpText;
