import { createContext, useState, useEffect } from "react";
import { getAdminView } from "./Services/appServices";
import Spinner from "react-bootstrap/Spinner";

const DataContext = createContext();

const DataProvider = ({ children }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getAdminView()
      .then((res) => {
        console.log("🚀 ~ file: DataContext.js:14 ~ .then ~ res:", res);
        setData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <DataContext.Provider value={{ data: data, loading: loading }}>
      {loading ? (
        <div className="full_screen_loader">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>{children}</>
      )}
    </DataContext.Provider>
  );
};

export { DataContext, DataProvider };
