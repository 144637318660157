export const Data = {
  homePage: {
    banner: {
      heading: "Welcome to IDUK",
      description: `I am Hirdesh Gupta Co-Founder and Director of IDUK (Indian
            Diaspora in UK). I am a social activist who strives to
            contribute as much as possible to the society.`,
      buttonText: "Let's Talk",
    },
    work: {
      heading: "Why work with me?",
      list: [
        {
          item: `<b>10 years of experience</b> in WordPress development,
                    meaning a deep understanding of the platform and its
                    capabilities.`,
        },
        {
          item: `I am a <b>problem solver</b> and not a "yes man". I keep
              asking until I have an overview of the entire area that the
              project covers, this way I can ensure that the solution I
              provide is the best possible one you and your customers.`,
        },
        {
          item: `Proficient in using <b>multiple languages</b> such as PHP,
            Javascript (and React JS), CSS, HTML`,
        },
        {
          item: `A dedication to help your business achieve its goals</b>
            and take your online presence to the next level.`,
        },
        {
          item: `Proven track record</b> of delivering high-quality,
            professional websites and applications that are easy to use,
            visually appealing, and optimized for search engines.`,
        },
        {
          item: `<b>Strong communication skills</b> to work closely with
            clients and understand their goals and objectives`,
        },
        {
          item: `Specialised in creating custom WordPress websites, plugins and
            bridges tailored to the <b>unique needs of your business.</b>`,
        },
        {
          item: `I am committed to deliver your projects
            <b>on time and on budget</b>, while also going the
            <b>extra mile</b> to ensure
            <b>complete client satisfaction</b> with the final product.`,
        },
      ],
    },
    helpText: {
      heading: "IDUK",
      gridData: [
        {
          logo: require("../assets/images/charity.png"),
          logoTitle: "Charity",
          logoDescription:
            "We work towards supporting individuals and communities in need through various initiatives and programs.",
        },
        {
          logo: require("../assets/images/meetandgreet.png"),
          logoTitle: "Meet & Greet",
          logoDescription: `Our meet & greet events aim to foster a sense of community
          and bring people together through various activities and
          gatherings.`,
        },
        {
          logo: require("../assets/images/socialGath.png"),
          logoTitle: "Social Gathering",
          logoDescription: `We organize social gatherings to provide a platform for
            individuals to connect, share ideas, and create meaningful
            relationships.`,
        },
        {
          logo: require("../assets/images/eve.png"),
          logoTitle: "Events",
          logoDescription: `Our events aim to create awareness, educate, and inspire
            individuals towards positive social change.`,
        },
        {
          logo: require("../assets/images/religious.png"),
          logoTitle: "Religious Events",
          logoDescription: `We organize religious events to bring together individuals
            from different backgrounds and promote mutual respect and
            understanding.`,
        },
        {
          logo: require("../assets/images/news.png"),
          logoTitle: "News",
          logoDescription: `Stay up-to-date with our latest news and events, and find
            out more about our work in the community.`,
        },
      ],
    },
    client: {
      heading: `I Believe In Making Connects
      <br /> And Nurture Them`,
      clientList: [
        {
          logo: require("../assets/images/client1.jpg"),
          title: "Service 1",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce hendrerit lorem vitae mauris dictum iaculis.",
        },
        {
          logo: require("../assets/images/client2.jpg"),
          title: "Service 2",
          description: `Nulla non mauris ac felis consequat semper. Vestibulum
            suscipit urna vel velit malesuada, in vestibulum justo
            tristique.`,
        },
        {
          logo: require("../assets/images/client3.jpg"),
          title: "Service 3",
          description: `Sed euismod leo sed erat maximus, ut aliquet leo elementum. Proin scelerisque augue ac sapien rutrum, sit amet sagittis sapien faucibus.`,
        },
        {
          logo: require("../assets/images/client4.jpg"),
          title: "Service 4",
          description: `Sed euismod leo sed erat maximus, ut aliquet leo elementum. Proin scelerisque augue ac sapien rutrum, sit amet sagittis sapien faucibus.`,
        },
        {
          logo: require("../assets/images/client5.jpg"),
          title: "Service 5",
          description: `Sed euismod leo sed erat maximus, ut aliquet leo elementum. Proin scelerisque augue ac sapien rutrum, sit amet sagittis sapien faucibus.`,
        },
        {
          logo: require("../assets/images/client6.jpg"),
          title: "Service 6",
          description: `Sed euismod leo sed erat maximus, ut aliquet leo elementum. Proin scelerisque augue ac sapien rutrum, sit amet sagittis sapien faucibus.`,
        },
      ],
    },
  },
  latestBlog: {
    heading: "Latest Articles",
    blogList: [
      {
        image: require("../assets/images/blog1.jpg"),
        title: "Article Title        ",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum dapibus nisi eu purus vulputate, eu lacinia lectus malesuada.",
        publishDate: "March 1, 2023",
        linkText: "READ MORE",
      },
      {
        image: require("../assets/images/blog2.jpg"),
        title: "Article Title",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum dapibus nisi eu purus vulputate, eu lacinia lectus malesuada.",
        publishDate: "February 28, 2023",
        linkText: "READ MORE",
      },
      {
        image: require("../assets/images/blog3.jpg"),
        title: "Article Title",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum dapibus nisi eu purus vulputate, eu lacinia lectus malesuada.",
        publishDate: "February 27, 2023",
        linkText: "READ MORE",
      },
    ],
  },
  biographyPage: {
    name: "Hirdesh Gupta",
    email: "guptahirdesh@gmail.com",
    address: "28, Laxington Appartment, Slough, SL2 5GQ",
    mobile: "07384234945",
    profileList: [
      "I am a highly motivated individual who has passion to contribute and give back to the society. I respect all the religions and believe in equality. I am involved many social causes, do lots of charity work and try to contribute as much as possible for the community through my volunteer work.",
      "I am an IT professional by profession and work for an MNC in UK. I have expertise in social media engagement, media management, campaign management and event management.",
      "I have travelled across the world and visited more than 25 countries so far in 5 continents (Asia, Africa, Europe, Australia, and America)",
    ],
    socialWork: [
      "Constituency captain of Slough / South East campaign team for Ready 4 Rishi",
      "Core team member of Vivek Agnihotri’s Speaker corner event on #HumanityTour – Responsible for Social media, print and electronic media management.",
      "Parents Governor of Herschel Grammar School, Slough, UK",
      "Executive Member of Slough Conservatives Association",
      "A panel Member of candidate selection committee of Slough Conservatives Association.",
      "Managing a community group called Indian Diaspora in UK",
      "Core Team Member of Reach India UK Group",
      "Managed the election campaign of various conservative candidates in Hounslow in last local election in May-2022",
      "Managed the election campaign of Alok Sharma (MP – Reading West) in UK’s general election in 2019.",
      "Organising committee member of Reading Mela Event 2018/19.",
      "Organised a Car rally in London to support BJP’s UP election for CM Yogi Adityanath",
      "Organised Hanuman Chalisa event at Winsor in protest in April 2022 of illegal arrest of Mr. Rana and Mrs Rana by MVA govt in Maharashtra, India.",
      "Digital Ambassador of Narendra Modi’s election campaign 2014 and 2019.",
      "Ex General Secretary of HSPA (Hyderabad School Parents Association)",
      "Ex-President of Ramky One Kosmos welfare association, Hyderabad, India",
      "Anna Andolan Activist in 2012 (Managed South India chapter of India Against Corruption)",
      "Organised many cultural events, sporting events in past",
      "Protested against GHMC Hyderabad against the Park was turned into Dumpyard. We won the case against Telangana government and got the park back for residence.",
      "Media Management, having lots of contacts in Media (India and UK) for an event coverage, Press Release, Press Note. Organised various Press Conferences for social causes.",
    ],
    otherData: {
      Education: [
        {
          Degree: "Masters in Computer Application",
          University: "Bundelkhand University Jhansi U.P.",
          Year: "2003",
        },
        {
          Degree: "Bachelor’s in Science",
          University: "Bundelkhand University Jhansi U.P.",
          Year: "2000",
        },
      ],
      Skills: [
        {
          Category: "IT Professional",
          Expertise: "Cloud transformation project management",
        },
        {
          Category: "Social Media Expert",
          Expertise:
            "Facebook, Instagram, Twitter, Telegram, Whatsapp, Youtube and Streamyard.",
        },
      ],
      Hobbies: [
        "Running, Badminton, Swimming, and biking",
        "Volunteering and event management in social and community events.",
      ],
      Employment: [
        {
          Position: "Senior Programme Manager",
          Company: "Oracle Corporation Ltd UK",
          Year: "since 2011",
        },
        {
          Position: "Project Manager",
          Company: "Harsco Corporation Inc.",
        },
        {
          Position: "Project Manager",
          Company: "Tech Mahindra (formerly known as Satyam Computer Ltd.)",
        },
        {
          Position: "Senior Associate",
          Company: "Vodafone Limited",
        },
      ],
    },
  },
  footer: {
    Email: "guptahirdesh@gmail.com",
    Phone: "07384234945",
    Address: "28, Laxington Apartment, Slough, SL2 5GQ",
    AboutMe: {
      ProfileName: "Hirdesh Gupta",
      Role: "Co-Founder and Director",
      Organization: "IDUK (Indian Diaspora in UK)",
      Bio: "I am a social activist who strives to contribute as much as possible to society.",
    },
    SocialMediaLinks: {
      FacebookLink: "https://www.facebook.com/guptahirdesh",
      TwitterLink: "https://twitter.com/guptahirdesh",
      InstagramLink: "https://www.instagram.com/hirdeshg/",
    },
    CopyRight: "2023 My Portfolio Website",
  },
};
