import React, { useState } from "react";
import { TwitterTimelineEmbed } from "react-twitter-embed";

const TwitterFeeds = () => {
  const [username] = useState("rp99773898");
  const [height] = useState(400);

  return (
    <div style={{ marginLeft: 300, marginRight: 300 }}>
      <TwitterTimelineEmbed
        sourceType="profile"
        screenName={username}
        options={{ height: height }}
      />
    </div>
  );
};

export default TwitterFeeds;
