import React, { useState, useEffect, useContext } from "react";
import "../../../styles/Client.css";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Data } from "../../Data";
import { DataContext } from "../../../DataContext";

const Client = () => {
  const [clientData, setClientData] = useState({});
  const {
    data: {
      homePage: {
        client: { heading, clientList },
      },
    },
  } = useContext(DataContext);
  useEffect(() => {
    setClientData(Data.homePage.client);
  }, [clientData]);
  return (
    <>
      <section className="our_Service">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section_title text-center">
                <AnimationOnScroll
                  animateIn="animate__zoomIn"
                  animateOnce={true}
                >
                  <h2
                    dangerouslySetInnerHTML={{
                      __html: heading,
                    }}
                  ></h2>
                </AnimationOnScroll>
              </div>
              <div className="row">
                {clientList && (
                  <>
                    {clientList.map((v, i) => {
                      return (
                        <div className="col-md-4" key={`client_list_data_${i}`}>
                          <AnimationOnScroll
                            animateIn="animate__fadeInUp animate__delay-0.5s"
                            animateOnce={true}
                          >
                            <div className="s_card">
                              <img
                                src={`${process.env.REACT_APP_BASE_URL}/${v.logo}`}
                                alt="Card"
                              />
                              <div className="s_card_caption">
                                <h3 className="card-title">{v.title}</h3>
                                <p className="card-description">
                                  {v.description}
                                </p>
                              </div>
                            </div>
                          </AnimationOnScroll>
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Client;
