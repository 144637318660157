import React, { useEffect, useState, useContext } from "react";
import Layout from "../../components/Layout/Layout";
import Bio3 from "../../assets/images/Bio3.png";
import instagram1 from "../../assets/images/instagram1.png";
import twitter1 from "../../assets/images/twitter1.png";
import "./biography.css";
import { FaFacebook } from "react-icons/fa";
import { Col, Container, Row } from "react-bootstrap";
import BannerSection from "../../assets/images/new_images/banner1.jpeg";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Data } from "../Data";
import { DataContext } from "../../DataContext";

const Default = () => {
  const [biographyData, setBiographyData] = useState({});

  const {
    data: {
      biographyPage: {
        address,
        email,
        mainHeading,
        mobile,
        name,
        otherData,
        personalInfoTitle,
        profileList,
        socialWork,
      },
    },
  } = useContext(DataContext);

  useEffect(() => {
    setBiographyData(Data.biographyPage);
  }, []);
  return (
    <Layout>
      <section
        className="page_banner"
        style={{
          backgroundImage: `url(${BannerSection})`,
        }}
      >
        <Container>
          <Row>
            <Col md={12}>
              <h1>{mainHeading}</h1>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="biography_content pt-5 pb-5">
        <Container>
          <Row>
            <Col md={4}>
              <AnimationOnScroll
                animateIn="animate__fadeInLeft animate__delay-0.5s"
                animateOnce={true}
              >
                <div className="bio_info">
                  <img className="profile-image" src={Bio3} alt="Profile" />
                  <div className="text-center">
                    <h2>{name}</h2>
                    <div className="social-icons">
                      <a
                        href="https://www.facebook.com/guptahirdesh"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FaFacebook size={50} />
                      </a>
                      <a
                        href="https://www.instagram.com/hirdeshg/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={instagram1} alt="" height={55} width={55} />
                      </a>
                      <a
                        href="https://twitter.com/guptahirdesh"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={twitter1} alt="" height={50} width={50} />
                      </a>
                    </div>
                  </div>
                </div>
              </AnimationOnScroll>
            </Col>
            <Col md={8}>
              <div className="bio_detail">
                <h3>Personal Social Profile</h3>
                <AnimationOnScroll
                  animateIn="animate__fadeInUp animate__delay-0.5s"
                  animateOnce={true}
                >
                  <ol className="personal_profile_bio">
                    {profileList && (
                      <>
                        {profileList.map((v, i) => {
                          return (
                            <li key={`biography_profile_list_data_${i}`}>
                              <p>{v.item}</p>
                            </li>
                          );
                        })}
                      </>
                    )}
                  </ol>
                </AnimationOnScroll>

                <div className="social_work_bio">
                  <h3>Social Work and Achievements</h3>
                  <div>
                    <AnimationOnScroll
                      animateIn="animate__fadeInUp animate__delay-0.5s"
                      animateOnce={true}
                    >
                      <ul>
                        {socialWork && (
                          <>
                            {socialWork.map((v, i) => {
                              return (
                                <li key={`biography_social_work_data_${i}`}>
                                  {v.item}
                                </li>
                              );
                            })}
                          </>
                        )}
                      </ul>
                    </AnimationOnScroll>
                  </div>
                </div>
                <div className="education_skill_bio">
                  <h3>Education, Skills and Hobbies</h3>
                  <AnimationOnScroll
                    animateIn="animate__fadeInUp animate__delay-0.5s"
                    animateOnce={true}
                  >
                    <ul className="educationul">
                      <strong className="mb-2 d-block">Education</strong>
                      {otherData && (
                        <>
                          {otherData["education"].map((v, i) => {
                            return (
                              <li key={`education_info_data_${i}`}>
                                ➢ {v.item}
                              </li>
                            );
                          })}
                        </>
                      )}
                    </ul>
                  </AnimationOnScroll>
                  <AnimationOnScroll
                    animateIn="animate__fadeInUp animate__delay-0.5s"
                    animateOnce={true}
                  >
                    <ul>
                      <strong className="mb-2 d-block">Skills</strong>
                      {otherData && (
                        <>
                          {otherData["skills"].map((v, i) => {
                            return (
                              <li key={`skills_info_data_${i}`}>➢ {v.item}</li>
                            );
                          })}
                        </>
                      )}
                    </ul>
                  </AnimationOnScroll>
                  <AnimationOnScroll
                    animateIn="animate__fadeInUp animate__delay-0.5s"
                    animateOnce={true}
                  >
                    <ul>
                      <strong className="mb-2 d-block">Hobbies:</strong>
                      {otherData && (
                        <>
                          {otherData["hobbies"].map((v, i) => {
                            return (
                              <li key={`hobbies_info_data_${i}`}>➢ {v.item}</li>
                            );
                          })}
                        </>
                      )}
                    </ul>
                  </AnimationOnScroll>
                </div>
                <div className="employment_bio">
                  <h3>Employment</h3>
                  <AnimationOnScroll
                    animateIn="animate__fadeInUp animate__delay-0.5s"
                    animateOnce={true}
                  >
                    <ul>
                      {otherData && (
                        <>
                          {otherData["Employment"].map((v, i) => {
                            return (
                              <li key={`employment_info_data_${i}`}>
                                ➢ {v.item}
                              </li>
                            );
                          })}
                        </>
                      )}
                    </ul>
                  </AnimationOnScroll>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default Default;
