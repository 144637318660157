import React from 'react'
import Layout from "../components/Layout/Layout";
import Carousel from "better-react-carousel";
import rishi6 from "../assets/images/rishi6.jpg";

const GDetail6 = () => {
  return (
    <Layout>
      <Carousel cols={1} rows={1} gap={10} loop>
        <Carousel.Item>
          <img width="100%" alt='' src={rishi6} style={{ height: 500 }} />
        </Carousel.Item>
      </Carousel>
      <div className="container">
        <h1 className="text-center">The Lady</h1>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Error
          adipisci distinctio maxime, quam nam, corporis reiciendis dolorem
          sequi, enim dicta ipsa! Quis fuga ea nulla animi, quo unde magnam
          reprehenderit deserunt deleniti similique voluptates sequi doloremque
          tenetur, minus quisquam. Sit fugiat maiores earum ullam reiciendis
          iure excepturi? Delectus, nisi minima similique fugit est cupiditate,
          in nemo animi id fuga omnis? Itaque nulla fuga corrupti a, asperiores
          tenetur dolorum? Reprehenderit similique nulla atque ipsam amet
          dolorem, quasi nam soluta delectus a iste labore, itaque consequuntur
          voluptate excepturi, asperiores magni ipsum? Ipsum cupiditate sapiente
          dolor iusto porro nam temporibus earum soluta incidunt?
        </p>
      </div>
    </Layout>
  )
}

export default GDetail6
