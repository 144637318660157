import React from "react";
import Layout from "../../components/Layout/Layout";
import LatestBlog from "../../components/LatestBlog";
import HelpForm from "./components/HelpForm";
import Banner from "./components/Banner";
import Work from "./components/Work";
import HelpText from "./components/HelpText";
import Client from "./components/Client";
import "./home.css";
import "animate.css/animate.min.css";

const Default = () => {
  return (
    <Layout>
      <Banner />
      <Work />
      <HelpText />
      <Client />
      <LatestBlog />
      <HelpForm />
    </Layout>
  );
};

export default Default;
