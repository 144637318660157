import React, { useRef, useEffect } from "react";
import Instafeed from "instafeed.js";
import Layout from "../../components/Layout/Layout";
import TwitterFeeds from "../../components/TwitterFeeds";
import instagram1 from "../../assets/images/instagram1.png";
import twit from "../../assets/images/twit.png";
import FacebookFeeds from "../../components/FacebookFeeds";

const Default = (props) => {
  const feedElement = useRef(null);
  useEffect(() => {
    const feed = new Instafeed({
      accessToken:
        "IGQVJXTXlxSHJTMG5JdERmTFRoOGNtc3F4X1R3WXJraDk3ZAHRBMFpoM2Y4NUNsMnNXUWVyLWk5TTFKX281T0c3UkRTbXNXZA1l4VEd3OEt1R2QtcjdXZA0Y2R2JYT1UtMFktRmlYbUZAYTmtZAVTRUMHZACQgZDZD",
      target: feedElement.current,
      template:
        '<a href="{{link}}" target="_blank" ><img src="{{image}}" width="180px" height="180px" /><p>{{caption}}</p></a>',
      limit: 6,
      resolution: "standard_resolution",
    });
    feed.run();
  }, []);

  return (
    <Layout>
      {/* Instagram */}

      <div style={{ backgroundColor: "#F5F5F5" }}>
        <div>
          <img
            src={instagram1}
            alt=""
            className="rounded mx-auto d-block"
            style={{ height: 100, width: 100, padding: 20 }}
          />
        </div>
        <div
          className="row row-cols-1 row-cols-md-4 g-2 container-sm "
          style={{
            display: "flex",
            justifyContent: "center",
            paddingLeft: "150px",
          }}
          ref={feedElement}
        ></div>
      </div>

      <div
        style={{ backgroundColor: "#2AAAE0", padding: "20px 10px 20px 60px" }}
      >
        <div>
          <img
            src={twit}
            alt=""
            className="rounded mx-auto d-block"
            style={{ height: 100, width: 100, padding: 20 }}
          />
        </div>
        <TwitterFeeds />
      </div>

      <FacebookFeeds />
    </Layout>
  );
};

export default Default;
