import React, { useState, useEffect, useContext } from "react";
import "../../../styles/Style.css";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Data } from "../../Data";
import WorkBanner from "../../../assets/images/new_images/work_banner.jpeg";
import { DataContext } from "../../../DataContext";

const Work = () => {
  const [workData, setWorkData] = useState({});

  const {
    data: {
      homePage: {
        work: { heading, list },
      },
    },
  } = useContext(DataContext);

  useEffect(() => {
    setWorkData(Data.homePage.work);
  }, [workData]);
  return (
    <div>
      <section className="why_work">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <AnimationOnScroll
                animateIn="animate__fadeInLeft"
                animateOnce={true}
              >
                <div>
                  <img alt="not found" src={WorkBanner} />
                </div>
              </AnimationOnScroll>
            </div>
            <div className="col-lg-8">
              <div className="section_title">
                <AnimationOnScroll
                  animateIn="animate__fadeInUp"
                  animateOnce={true}
                >
                  <h2>{heading}</h2>
                </AnimationOnScroll>
              </div>
              <AnimationOnScroll
                animateIn="animate__fadeInUp animate__delay-0.5s"
                animateOnce={true}
              >
                <ul className="work_uline">
                  {list && (
                    <>
                      {list.map((v, i) => {
                        return (
                          <li
                            key={`work_list_data_${i}`}
                            dangerouslySetInnerHTML={{ __html: v.item }}
                          ></li>
                        );
                      })}
                    </>
                  )}
                </ul>
              </AnimationOnScroll>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Work;
