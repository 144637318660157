import React, { useEffect, useState, useContext } from "react";
import "../styles/LatestBlog.css";
import { Link } from "react-router-dom";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Data } from "../pages/Data";
import { DataContext } from "../DataContext";

const LatestBlog = () => {
  const [blogData, setBlogData] = useState({});
  const {
    data: {
      latestBlog: { heading, blogList },
    },
  } = useContext(DataContext);
  useEffect(() => {
    setBlogData(Data.latestBlog);
  }, [blogData]);

  useEffect(() => {
    // AOS.init();
  }, []);

  return (
    <>
      <section id="latest-articles">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section_title text-center">
                <AnimationOnScroll
                  animateIn="animate__zoomIn animate__delay-0.5s"
                  animateOnce={true}
                >
                  <h2>{heading}</h2>
                </AnimationOnScroll>
              </div>
              <div className="row articles">
                {blogList && (
                  <>
                    {blogList.map((v, i) => {
                      return (
                        <div
                          className="col-md-4"
                          key={`latest_blog_list_data_${i}`}
                        >
                          <AnimationOnScroll
                            animateIn="animate__fadeInUp animate__delay-0.5s"
                            animateOnce={true}
                          >
                            <div className="article">
                              <img
                                src={`${process.env.REACT_APP_BASE_URL}/${v.image}`}
                                alt="Article "
                              />
                              <div className="article-info">
                                <h3>{v.title}</h3>
                                <p className="post_date_vs">
                                  Published on{" "}
                                  <span className="date">{v.publishDate}</span>
                                </p>
                                <p>{v.description}</p>
                                <Link to="/gallery" className="read-more">
                                  {v.linkText}
                                </Link>
                              </div>
                            </div>
                          </AnimationOnScroll>
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LatestBlog;
