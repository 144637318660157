import React from "react";
import { Modal, Button } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function GalleryModal({ open, onClose, dialogData }) {
  console.log("GalleryModal data", dialogData[0]);
  const data = dialogData[0];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  console.log("GalleryModal itrate data", data);
  return (
    <Modal className="gallery_model" show={open} onHide={onClose} size="lg">
      <Modal.Body>
        {data?.images?.length > 0 ? (
          <Slider {...settings}>
            {data.images.map((v, i) => {
              return (
                <div key={`gallery_slider_${i}`}>
                  <img src={v} alt="not found" />
                </div>
              );
            })}
          </Slider>
        ) : null}
      </Modal.Body>
      <Modal.Header closeButton>
        <Modal.Title>{data?.title}</Modal.Title>
      </Modal.Header>
    </Modal>
  );
}

export default GalleryModal;
