import React, { useState, useEffect } from "react";
import Facebook1 from "../assets/images/Facebook1.png";

function FacebookFeeds() {
  const [post, setPost] = useState(null);

  useEffect(() => {
    async function fetchPost() {
      const response = await fetch(
        `https://graph.facebook.com/me?fields=id,name,posts&access_token=EAAHaJk14xfgBACGueX77xyYNz4kd6uhRsDmQAzISpJodyzOCH9UwNsRK9mLZCWueTZBmWcSkNysKVXx1zo8xBQJbG9kZCFa0q6LJBcng0trbh4ZAzLEsEjzZACBP4tWfnIZBkrZAJAs8x6YYZCQoQZCLCJt2WzONkGzbhZALQE8CecWAvQSgT493Xs`
      );
      const data = await response.json();
      console.log(data)
      setPost(data);
    }
    fetchPost();
  }, []);

  if (!post) {
    return <p>Loading...</p>;
  }
  return (
    <>
      {/* <div>
        <FacebookProvider appId="521333020083704">
        <EmbeddedPost href="https://www.facebook.com" width="500" />
      </FacebookProvider>

        {post.map(pos => (
        <div key={pos.id}>
        <h2>{pos.message}</h2>
        <p>{pos.created_time}</p>
        <img src={pos.full_picture} alt={pos.message} />
        <img src={pos.public_profile} alt={pos.message} />
      </div>
      
    ))}
        <div>
        <h2>{post.name}</h2>
        <h2>{post.id}</h2>
        <h2>{post.posts.data[1].created_time}</h2>
        <h2>{post.posts.data[4].message}</h2>
        <h2>{post.posts.data[1].id}</h2>
        <img src={post.posts.data[1]} alt="" />
      </div>
      </div> */}
      <div>
        <img
          src={Facebook1}
          alt=""
          className="rounded mx-auto d-block"
          style={{ height: 100, width: 100}}
        />
      </div>
      <div style={{display:"flex", justifyContent:"center", backgroundColor:"#39569B",padding:20 }}>
        <iframe
          src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fprofile.php%3Fid%3D100090906631743&tabs=timeline&width=340&height=496&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=5200915213344667"
          width="340"
          height="500"
          style={{ border: "none", overflow: "hidden"}}
          scrolling="no"
          title="Facebook Feeds"
          frameBorder="0"
          allowFullScreen="true"
          allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
        ></iframe>
      </div>
    </> 
  );
}

export default FacebookFeeds;
