import React, { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import "../../../styles/HelpForm.css";
import { AnimationOnScroll } from "react-animation-on-scroll";
import ContactImage from "../../../assets/images/new_images/contact_page.jpeg";

const HelpForm = () => {
  const [verfied, setVerifed] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    budget: "",
    companySize: "",
    preferredDeadline: "",
    projectDescription: "",
  });

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const response = await fetch("http://localhost:8000/api/send", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });
    const result = await response.text();
    console.log(result);
    setFormData({
      name: "",
      email: "",
      budget: "",
      companySize: "",
      preferredDeadline: "",
      projectDescription: "",
    });
  };

  //recaptcha function
  function onChange(value) {
    console.log("Captcha value:", value);
    setVerifed(true);
  }

  //react-tostify

  // const notify = () =>
  //   toast.success("Message Send Successfully", {
  //     position: "top-right",
  //     autoClose: 5000,
  //     hideProgressBar: false,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true,
  //     progress: undefined,
  //     theme: "light",
  //   });

  useEffect(() => {
    // AOS.init();
  }, []);
  return (
    <>
      <section id="contact">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-sm-5">
                  <div className="contact_home_img">
                    <img src={ContactImage} alt="not found" />
                  </div>
                </div>
                <div className="col-sm-7">
                  <div className="section_title">
                    <AnimationOnScroll
                      animateIn="animate__fadeInUp"
                      animateOnce={true}
                    >
                      <h2>Contact Me</h2>
                    </AnimationOnScroll>
                  </div>
                  <AnimationOnScroll
                    animateIn="animate__fadeInUp"
                    animateOnce={true}
                  >
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="name">Name</label>
                            <input
                              name="name"
                              onChange={handleChange}
                              value={formData.name}
                              type="text"
                              id="name"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input
                              name="email"
                              onChange={handleChange}
                              value={formData.email}
                              type="email"
                              id="email"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="subject">Budget</label>
                            <select
                              name="budget"
                              onChange={handleChange}
                              value={formData.budget}
                              id="subject"
                            >
                              <option selected>Choose</option>
                              <option>€150 - One hour consulting call</option>
                              <option>€3000 - €5000</option>
                              <option>€5000 - €10.000</option>
                              <option>€10.000+</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="subject">Company-Size</label>
                            <select
                              name="companySize"
                              onChange={handleChange}
                              value={formData.companySize}
                              id="subject"
                            >
                              <option selected>Choose</option>
                              <option>1</option>
                              <option>2-10</option>
                              <option>10-50</option>
                              <option>50-100</option>
                              <option>100+</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label htmlFor="name">Preferred-Deadline</label>
                            <input
                              name="preferredDeadline"
                              onChange={handleChange}
                              value={formData.preferredDeadline}
                              type="text"
                              id="name"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label htmlFor="message">Project Description</label>
                            <textarea
                              name="projectDescription"
                              onChange={handleChange}
                              value={formData.projectDescription}
                              id="message"
                              required
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <ReCAPTCHA
                          sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                          onChange={onChange}
                        />
                        &nbsp;
                        <div>
                          <button
                            // onClick={notify}
                            disabled={!verfied}
                            type="submit"
                            className="custom_btn"
                          >
                            Send Message
                          </button>
                        </div>
                      </div>
                    </form>
                  </AnimationOnScroll>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HelpForm;
