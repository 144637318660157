import "./App.css";
import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/home";
import Events from "./pages/events";
import Contact from "./pages/contact";
import Social from "./pages/social";

import Biography from "./pages/biography";
import Gallery from "./pages/gallery";
import Gall from "./pages/Gall";
import GDetail1 from "./Details/GDetail1";
import GDetail2 from "./Details/GDetail2";
import GDetail3 from "./Details/GDetail3";
import GDetail4 from "./Details/GDetail4";
import GDetail5 from "./Details/GDetail5";
import GDetail6 from "./Details/GDetail6";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/gallery" element={<Gallery />} />

        <Route path="/social" element={<Social />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/bio" element={<Biography />} />
        {/* <Route path="/blog" element={<LatestBlog />} /> */}

        <Route path="/gall" element={<Gall />} />
        <Route path="/g1" element={<GDetail1 />} />
        <Route path="/g2" element={<GDetail2 />} />
        <Route path="/g3" element={<GDetail3 />} />
        <Route path="/g4" element={<GDetail4 />} />
        <Route path="/g5" element={<GDetail5 />} />
        <Route path="/g6" element={<GDetail6 />} />
        <Route path="/events" element={<Events />} />
      </Routes>
    </>
  );
}

export default App;
